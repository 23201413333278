import { Navigate, useNavigate, useParams } from "react-router-dom";

import { ArrowIcon, LoadingState, SectionHeader } from "~/common/components";
import { DIRECTIONS, PATIENT_STATUS } from "~/common/constants";
import { formatMedicationName } from "~/common/utils";
import { useGetPatientMedication } from "~/patients/hooks/queries/medications/useGetPatientMedication";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { ROUTES } from "~/patients/router";
import { CurrentTreatment } from "./CurrentTreatment";
import { TreatmentHistory } from "./TreatmentHistory";

export const PatientMedicationDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: user } = useGetCurrentPatient();

  const { data: medicationData, isLoading } = useGetPatientMedication(id ?? "");
  const currentMedication = medicationData?.patient_medications_details[0];

  if (user?.status !== PATIENT_STATUS.ongoing_care)
    return <Navigate to={ROUTES.notFound} />;

  if (isLoading) return <LoadingState className="h-screen-calculated" />;

  const treatmentHistoryData = [
    ...(medicationData?.patient_medications_details ?? []),
  ].slice(1);

  return (
    <div className="flex h-screen-calculated w-full flex-col gap-5 overflow-y-scroll p-6 text-sm lg:px-44 lg:py-8">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center gap-1.5"
      >
        <ArrowIcon direction={DIRECTIONS.left} />
        <p>Back</p>
      </button>
      <SectionHeader className="text-3xl">
        {formatMedicationName(medicationData?.medication)}
      </SectionHeader>

      <div className="flex flex-col gap-6">
        {currentMedication && (
          <div className="flex flex-col gap-4">
            <SectionHeader>Current treatment</SectionHeader>
            <CurrentTreatment patientMedication={currentMedication} />
          </div>
        )}
        {!!treatmentHistoryData.length && (
          <div className="flex flex-col gap-4">
            <SectionHeader>Treatment history</SectionHeader>
            <div className="flex flex-col gap-4">
              {treatmentHistoryData.map((patientMedication) => (
                <TreatmentHistory
                  key={patientMedication.id}
                  patientMedication={patientMedication}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
