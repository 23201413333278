import { z } from "zod";

export const consultationFeeSchema = z.object({
  consultation_fee: z
    .number({ message: "Please enter a valid number (e.g., 25.6)." })
    .min(0, "Consultation fee must be greater or equal than 0.")
    .nullable(),
});
export const paymentSettingsSchema = z.object({
  secret_key: z
    .string()
    .min(1, "Secret key is required.")
    .startsWith("rk_", { message: "Secret key must start with 'rk_'." }),
  publishable_key: z.string().min(1, "Publishable key is required."),
});
