import { CONSULTATIONS_QUESTION_TYPES } from "~/common/utils/consultations/shared";
import { replaceUnderscores } from "~/common/utils/replaceUnderscores";
import {
  INTAKE_MULTI_CHECK_VALUES,
  INTAKE_QUESTION_CODES,
} from "../intakeQuestionsData";

const INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS = [
  {
    title: "Trend",
    value: INTAKE_MULTI_CHECK_VALUES.steady,
    label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.steady),
  },
  {
    value: INTAKE_MULTI_CHECK_VALUES.getting_better,
    label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.getting_better),
  },
  {
    value: INTAKE_MULTI_CHECK_VALUES.getting_worse,
    label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.getting_worse),
  },
  {
    title: "Nature of episodes",
    value:
      INTAKE_MULTI_CHECK_VALUES.intermittent_and_fully_better_between_episodes,
    label: replaceUnderscores(
      INTAKE_MULTI_CHECK_VALUES.intermittent_and_fully_better_between_episodes,
    ),
  },
  {
    value:
      INTAKE_MULTI_CHECK_VALUES.intermittent_and_not_fully_better_between_episodes,
    label: replaceUnderscores(
      INTAKE_MULTI_CHECK_VALUES.intermittent_and_not_fully_better_between_episodes,
    ),
  },
  {
    title: "Frequency",
    value: INTAKE_MULTI_CHECK_VALUES.less_frequent_episodes,
    label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.less_frequent_episodes),
  },
  {
    value: INTAKE_MULTI_CHECK_VALUES.more_frequent_episodes,
    label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.more_frequent_episodes),
  },
  {
    value: INTAKE_MULTI_CHECK_VALUES.unpredictably_variable,
    label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.unpredictably_variable),
  },
] as const;

export const multiCheckQuestions = {
  [INTAKE_QUESTION_CODES.have_you_experienced_any_of_the_following_medical_issues]:
    {
      title:
        "Have you experienced any of the following medical issues? Please select all that apply.",
      type: CONSULTATIONS_QUESTION_TYPES.multi_check,
      options: [
        {
          value: INTAKE_MULTI_CHECK_VALUES.asthma,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.asthma),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.liver_issues,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.liver_issues),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.seizures,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.seizures),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.heart_history,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.heart_history),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.diabetes,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.diabetes),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.sleep_apnea,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.sleep_apnea),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.excessive_snoring,
          label: replaceUnderscores(
            INTAKE_MULTI_CHECK_VALUES.excessive_snoring,
          ),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.kidney_issues,
          label: replaceUnderscores(INTAKE_MULTI_CHECK_VALUES.kidney_issues),
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.head_traumas_losses_of_consciousness,
          label: "Head traumas / losses of consciousness",
        },
        {
          value: INTAKE_MULTI_CHECK_VALUES.none_of_the_above,
          label: replaceUnderscores(
            INTAKE_MULTI_CHECK_VALUES.none_of_the_above,
          ),
          isExclusiveOption: true,
        },
      ],
    },
  [INTAKE_QUESTION_CODES.depression_symptoms]: {
    title:
      'Please indicate how "Depression" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.sleep_issues_symptoms]: {
    title:
      'Please indicate how "Sleep issues" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.attention_and_focus_symptoms]: {
    title:
      'Please indicate how "Attention and focus issues" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.anxiety_symptoms]: {
    title:
      'Please indicate how "Anxiety" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.panic_attack_symptoms]: {
    title:
      'Please indicate how "Panic attacks" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.increased_spending_symptoms]: {
    title:
      'Please indicate how "Increased spending or shopping" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.appetite_changes_symptoms]: {
    title:
      'Please indicate how "Appetite changes" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.nightmare_symptoms]: {
    title:
      'Please indicate how "Nightmares" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.flashbacks_symptoms]: {
    title:
      'Please indicate how "Flashbacks" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.memory_problems_symptoms]: {
    title:
      'Please indicate how "Problems with memory" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.preoccupation_weight_symptoms]: {
    title:
      'Please indicate how "Preoccupation with weight or food" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.obsessions_symptoms]: {
    title:
      'Please indicate how "Obsessions" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.compulsions_symptoms]: {
    title:
      'Please indicate how "Compulsions" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.diminished_interest_symptoms]: {
    title:
      'Please indicate how "Diminished interest in previously enjoyable things" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.irritability_symptoms]: {
    title:
      'Please indicate how "Irritability" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.grand_ideas_symptoms]: {
    title:
      'Please indicate how "Grand ideas / feeling "on top of the world"" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.suicidal_thoughts_symptoms]: {
    title:
      'Please indicate how "Suicidal and/or harmful thoughts" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.impulsivity_symptoms]: {
    title:
      'Please indicate how "Impulsivity" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
  [INTAKE_QUESTION_CODES.other_symptoms]: {
    title:
      'Please indicate how "Other symptoms" has progressed from the time it began until today?',
    type: CONSULTATIONS_QUESTION_TYPES.multi_check,
    options: INTAKE_SYMPTOM_DETAIL_QUESTIONS_OPTIONS,
  },
} as const;
