import { z } from "zod";

import {
  ADMIT_TO_PRACTICE_VALUES,
  REFER_PATIENT_VALUES,
} from "~/providers/constants";

export const PRICE_TYPES = {
  default: "default",
  custom: "custom",
} as const;

const defaultFeeSchema = z.object({
  priceType: z.literal(PRICE_TYPES.default),
});

const customFeeSchema = z.object({
  priceType: z.literal(PRICE_TYPES.custom),
  price: z.string().regex(/^\d+(\.\d{1,2})?$/, "Price must be a valid number"),
});

export const consultationDetailSchema = z.discriminatedUnion("priceType", [
  customFeeSchema,
  defaultFeeSchema,
]);

export type ConsultationDetailType = z.infer<typeof consultationDetailSchema>;

export const completeAndSignSchema = z.object({
  admit_to_practice: z.enum(ADMIT_TO_PRACTICE_VALUES),
  refer_patient: z.enum(REFER_PATIENT_VALUES),
});

export type CompleteAndSignType = z.infer<typeof completeAndSignSchema>;
