import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import ErrorIcon from "../icons/ErrorIcon";
import SuccessIcon from "./SuccessIcon";

interface SuccessErrorLayoutProps {
  success?: boolean;
  children?: ReactNode;
  title: string;
  description?: string;
  className?: string;
  titleClassName?: string;
  descriptionClassName?: string;
}
const SuccessErrorLayout = ({
  success,
  children,
  title,
  description,
  className,
  titleClassName,
  descriptionClassName,
}: SuccessErrorLayoutProps) => {
  return (
    <div
      className={twMerge(
        "mx-auto flex h-full flex-col items-center justify-center gap-6 text-gray-700 md:w-2/3",
        className,
      )}
    >
      {success ? (
        <SuccessIcon className="h-24 w-24" />
      ) : (
        <ErrorIcon className="h-24 w-24" />
      )}
      <div className="flex flex-col items-center gap-2">
        <h3
          className={twMerge(
            "font-outfit text-2xl font-semibold tracking-wide",
            titleClassName,
          )}
        >
          {title}
        </h3>
        <p
          className={twMerge(
            "whitespace-pre-wrap px-3 text-center",
            descriptionClassName,
          )}
        >
          {description}
        </p>
      </div>
      <div className="flex flex-col items-center md:w-1/3">{children}</div>
    </div>
  );
};

export default SuccessErrorLayout;
