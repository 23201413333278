import {
  CalendarIcon,
  H2,
  LocationIcon,
  Typography,
} from "~/common/components";
import { TeamMember } from "~/common/utils";

interface AppointmentCompletedCardProps {
  title: string;
  provider: TeamMember;
}

export const AppointmentCompletedCard = ({
  title,
  provider,
}: AppointmentCompletedCardProps) => (
  <div className="flex flex-col gap-4 rounded-lg border border-primary-600 bg-primary-500 p-4 text-white">
    <div className="flex w-full flex-col gap-2 border-b border-primary-300 pb-2">
      <H2 className="text-xl">{title}</H2>
    </div>
    <div className="flex w-full flex-col gap-3">
      <div className="flex w-full flex-col items-start justify-between gap-4">
        <Typography className="flex items-center justify-start gap-2 font-medium text-white">
          <CalendarIcon className="size-6 text-white" /> Monday, May 25 at 3:00
          PM
        </Typography>
        <Typography className="flex items-start justify-start gap-2 text-white">
          <LocationIcon className="size-6" />
          <div className="flex flex-col gap-1">
            <p className="font-medium">{provider.preferred_reference}</p>
            <p className="font-light">3000 N Alafaya Trail</p>
            <p className="font-light">(205) 555-0100</p>
          </div>
        </Typography>
      </div>
    </div>
  </div>
);
