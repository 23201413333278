import { Navigate, useNavigate } from "react-router-dom";

import { ImagePromptCard, WaveBackgroundLayout } from "~/common/components";
import SuccessIcon from "~/common/components/layouts/SuccessIcon";
import { PATIENT_STATUS } from "~/common/constants";
import { useGetCurrentPatient } from "../../../hooks/queries/useGetCurrentPatient";
import { ROUTES } from "../../../router";

export const ScreeningAlreadyCompleted = () => {
  const navigate = useNavigate();
  const { data: user } = useGetCurrentPatient();

  if (user?.status === PATIENT_STATUS.ongoing_care)
    return <Navigate to={ROUTES.notFound} />;

  return (
    <WaveBackgroundLayout className="relative justify-center p-6">
      <ImagePromptCard
        title="You already completed this section!"
        handleContinue={() => navigate(ROUTES.leadPatientHome)}
        imageContainerClassName="bg-green-100"
        continueLabel="Back to Home"
      >
        <SuccessIcon className="w-full" />
      </ImagePromptCard>
    </WaveBackgroundLayout>
  );
};
