import { useState } from "react";
import { FieldErrors, UseFormRegisterReturn } from "react-hook-form";

import { TrashIcon } from "~/common/components";
import Input from "~/common/components/ui/Input";
import TextArea from "~/common/components/ui/Textarea";
import { getTodayDate } from "~/common/utils";
import { ConfirmDeleteListItem } from "~/patients/components/consultations/shared/ui";
import { JobsFormValues } from "~/patients/utils/consultations/intakes";

interface JobInputProps {
  register: (data: keyof JobsFormValues) => UseFormRegisterReturn<string>;
  handleDelete: () => void;
  isMedicationDataEmpty: boolean;
  errors?: FieldErrors<JobsFormValues>;
}

export const JobInput = ({
  handleDelete,
  isMedicationDataEmpty,
  errors,
  register,
}: JobInputProps) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = () =>
    setShowConfirmDeleteModal((prev) => !prev);

  const today = getTodayDate();
  return (
    <div className="flex w-full flex-col items-end gap-4 border-b border-neutral-700 pb-4">
      <Input
        id="place"
        label="Place of employment"
        error={errors?.place_of_employment?.message}
        {...register(`place_of_employment`)}
      />
      <Input
        id="title"
        label="Job title"
        error={errors?.job_title?.message}
        {...register(`job_title`)}
      />
      <div className="flex w-full items-center justify-between gap-2">
        <Input
          type="date"
          id="start"
          label="Date started"
          max={today}
          error={errors?.date_started?.message}
          {...register(`date_started`)}
        />
        <Input
          id="end"
          type="date"
          label="Date ended"
          max={today}
          error={errors?.date_ended?.message}
          {...register(`date_ended`)}
        />
      </div>
      <TextArea
        id="leave"
        label="Why did you leave this job?"
        placeholder="Enter reason for leaving job."
        error={errors?.why_did_you_leave?.message}
        {...register(`why_did_you_leave`)}
      />

      <button
        className="flex w-fit items-center gap-1.5 text-gray-10"
        type="button"
        onClick={
          isMedicationDataEmpty ? handleDelete : toggleConfirmDeleteModal
        }
      >
        Delete <TrashIcon className="text-gray-10" />
      </button>

      <ConfirmDeleteListItem
        show={showConfirmDeleteModal}
        onClose={toggleConfirmDeleteModal}
        onConfirm={handleDelete}
        description="Are you sure you want to delete this job?"
      />
    </div>
  );
};
