import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";

import DatePicker from "~/common/components/ui/DatePicker";
import Input from "~/common/components/ui/Input";
import TextArea from "~/common/components/ui/Textarea";
import { getTodayDate } from "~/common/utils";
import { TherapistInThePastQuestionData } from "~/common/utils/consultations/intake/interfaces";
import { ConsultationQuestionsLayout } from "~/patients/components/consultations/shared";
import {
  therapistInThePastFormSchema,
  TherapistInThePastFormValues,
  TherapistInThePastType,
} from "~/patients/utils/consultations/intakes";

export interface TherapistInThePastFormProps {
  question: TherapistInThePastQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: TherapistInThePastFormValues) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswers: TherapistInThePastType;
}

export const TherapistInThePastForm = ({
  question,
  onSubmit,
  handleGoBack,
  isSubmitting,
  disableBack,
  completedAnswers,
}: TherapistInThePastFormProps) => {
  const { title, subtitle } = question;
  const defaultValues = completedAnswers;

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm<TherapistInThePastFormValues>({
    defaultValues: {
      answer: defaultValues,
    },
    resolver: zodResolver(therapistInThePastFormSchema),
  });

  const saveAnswers: SubmitHandler<TherapistInThePastFormValues> = (data) =>
    onSubmit(data);

  const therapistName = watch("answer.therapist_name");
  const reasonForTherapy = watch("answer.reason_for_therapy");
  const dateStarted = watch("answer.date_started");
  const isNextDisabled = !therapistName || !reasonForTherapy || !dateStarted;

  const today = getTodayDate();

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      disableNext={isNextDisabled}
      isSubmitting={isSubmitting}
      title={title}
      subtitle={subtitle}
      disableBack={disableBack}
    >
      <Input
        id="therapist_name"
        label="Name of therapist"
        placeholder="Enter name"
        {...register("answer.therapist_name")}
        error={errors?.answer?.reason_for_therapy?.message}
      />
      <TextArea
        rows={3}
        id="reason_for_therapy"
        label="Reasons for therapy"
        placeholder={"Please enter your reasons for going to therapy."}
        {...register("answer.reason_for_therapy")}
        error={errors?.answer?.reason_for_therapy?.message}
        className="border border-gray-20 placeholder:not-italic"
      />
      <DatePicker
        id="date_started"
        label="Date started"
        max={today}
        {...register("answer.date_started")}
        error={errors?.answer?.date_started?.message}
      />
      <DatePicker
        id="date_completed"
        label="Date completed (optional)"
        max={today}
        {...register("answer.date_completed")}
        error={errors?.answer?.date_completed?.message}
      />
    </ConsultationQuestionsLayout>
  );
};
