import { PropsWithChildren } from "react";

import { WarningIcon } from "./icons";

export const WarningMessage = ({ children }: PropsWithChildren) => (
  <div className="flex gap-2">
    <div className="flex w-fit py-1">
      <WarningIcon />
    </div>
    <p className="text-sm leading-5 text-gray-70">{children}</p>
  </div>
);
