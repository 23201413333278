import { formatDate } from "../orders";
import { generateDiscontinuationTitle } from "./generateDiscontinuationTitle";

export const generateMedicationDatesRange = (
  start_date: string,
  discontinued_at?: string,
) => {
  const startedOn = `Started on ${formatDate(start_date)}`;
  if (!discontinued_at) return startedOn;
  return `${startedOn} - ${generateDiscontinuationTitle(discontinued_at)}`;
};
