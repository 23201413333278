import { YES_NO_NA_TYPES, YES_NO_NOT_SURE_TYPES } from "./data";

export const YesNoNA = [
  YES_NO_NA_TYPES.yes,
  YES_NO_NA_TYPES.no,
  YES_NO_NA_TYPES.na,
] as const;

export const YesNoNotSure = [
  YES_NO_NOT_SURE_TYPES.yes,
  YES_NO_NOT_SURE_TYPES.no,
  YES_NO_NOT_SURE_TYPES.notSure,
] as const;

export const intakeRadioOptions = [...YesNoNA, ...YesNoNotSure] as const;

export type IntakeOptionsValues = (typeof intakeRadioOptions)[number];

export type YesNoNAType = typeof YesNoNA;

export type YesNoNotSureType = typeof YesNoNotSure;

export type IntakeRadioValues = YesNoNAType | YesNoNotSureType;
