import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { ErrorText } from "./typography";

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  containerClassName?: string;
  label?: string;
  rows?: number;
  error?: string;
  children?: ReactNode;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      containerClassName,
      id,
      label,
      placeholder,
      rows,
      error,
      children,
      ...props
    },
    ref,
  ) => (
    <div
      className={twMerge(
        "flex w-full flex-col gap-2 font-light text-gray-70",
        containerClassName,
      )}
    >
      <div className="flex flex-row justify-between">
        <div className="flex w-full flex-col">
          <label className="text-sm font-medium" htmlFor={id}>
            {label}
          </label>
          <p className="text-sm font-normal leading-5 text-gray-35">
            {children}
          </p>
        </div>
      </div>
      <div className="relative">
        <textarea
          ref={ref}
          id={id}
          key={id}
          placeholder={placeholder}
          rows={rows}
          className={twMerge(
            "block w-full resize-none rounded-md border border-gray-300 bg-neutral-25 p-2.5 text-sm",
            "focus:border-gray-20 focus:outline-none focus:ring-0",
            "placeholder:text-sm placeholder:italic",
            "disabled:bg-gray-200 disabled:text-gray-700",
            className,
            error && "border-error bg-red-50 focus:border-error",
          )}
          aria-errormessage={`${id}_error`}
          {...props}
        />
      </div>
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  ),
);

TextArea.displayName = "TextArea";

export default TextArea;
