import { Button, ClockIcon, H2, Tag, Typography } from "~/common/components";
import { BUTTON_VARIANTS } from "~/common/constants";

interface PendingConsultationCardProps {
  title: string;
  subtitle?: string;
  duration: string;
  totalSections: number;
  currentSection: number;
  handleCompleteConsultation: () => void;
}

export const PendingConsultationCard = ({
  title,
  subtitle,
  duration,
  totalSections,
  currentSection,
  handleCompleteConsultation,
}: PendingConsultationCardProps) => (
  <div className="flex flex-col gap-4 rounded-lg border border-primary-600 bg-primary-500 p-4 text-white">
    <div className="flex w-full flex-col gap-2 border-b border-primary-300 pb-2">
      <H2 className="text-xl">{title}</H2>
      {subtitle && (
        <Typography className="text-neutral-50">{subtitle}</Typography>
      )}
    </div>
    <div className="flex w-full flex-col gap-3">
      <div className="flex w-full items-center justify-between">
        <Typography className="text-sm text-white">
          {currentSection}/{totalSections} sections completed
        </Typography>
        <Tag className="bg-white py-1 text-sm font-normal text-primary-600">
          <ClockIcon strokeWidth={1} className="size-4" /> {duration}
        </Tag>
      </div>
      <Button
        variant={BUTTON_VARIANTS.outline_black}
        size="sm"
        onClick={handleCompleteConsultation}
      >
        {currentSection === 0 ? "Start" : "Continue"}
      </Button>
    </div>
  </div>
);
