import {
  CONSULTATIONS_INPUTS_TYPES,
  CONSULTATIONS_QUESTION_TYPES,
} from "~/common/utils/consultations/shared";
import { INTAKE_QUESTION_CODES } from "../intakeQuestionsData";

export const textQuestions = {
  [INTAKE_QUESTION_CODES.you_indicated_you_have_seen_a_psychiatrist]: {
    title:
      "You indicated you have seen a psychiatrist in the past. Please provide more details.",
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        placeholder: "Enter years.",
        value: "what_years",
        label: "What years did you see a psychiatrist?",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
      {
        id: "2",
        placeholder: "Enter events.",
        value: "precipitating_event_happen_for_you_to_seek_help",
        label:
          "What precipitating events happen for you to seek help from a psychiatrist?",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.any_other_medical_issues_we_should_know_about]: {
    title: "Any other medical issues we should know about?",
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        placeholder: "Enter further description.",
        value: "value",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: false,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.how_much_caffeine_do_you_consume_daily]: {
    title: "How much caffeine do you consume daily?",
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        placeholder: "Enter further description.",
        value: "value",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.how_much_do_you_drink_alcohol]: {
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        placeholder: "Please describe further.",
        value: "how_much",
        label: "How much do you drink when you drink?",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
      {
        id: "2",
        placeholder: "Please describe further.",
        value: "how_many_days_a_week",
        label: "How many days a week do you drink?",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
      {
        id: "3",
        placeholder: "Please describe further.",
        value: "how_many_total_drinks_in_a_week",
        label: "How many total drinks in a week?",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.how_much_marijuana_do_you_consume]: {
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        placeholder: "Please describe further.",
        value: "how_much",
        label:
          "How much do you consume in grams/dollars in a week or in a month?",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
      {
        id: "2",
        placeholder: "Please describe further.",
        value: "how_many_days_a_week",
        label: "How many days a week do you use?",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.your_relationships_history]: {
    title: "Tell me a bit more about your relationship(s) history",
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        placeholder: "Enter further description.",
        value: "value",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.how_many_children_do_you_have_and_what_are_their_ages]:
    {
      title: "How many children do you have and what are their ages?",
      type: CONSULTATIONS_QUESTION_TYPES.text,
      inputs: [
        {
          id: "1",
          placeholder: "Please specify the number of children and their ages.",
          value: "value",
          type: CONSULTATIONS_INPUTS_TYPES.text_area,
          required: true,
        },
      ],
    },
  [INTAKE_QUESTION_CODES.what_did_teachers_say_about_you]: {
    title:
      "In grade school, what did teachers say about you at conferences and on report cards?",
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        placeholder:
          "Please provide details on what teachers mentioned about you at conferences and in report cards.",
        value: "value",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.how_much_do_you_drink_alcohol]: {
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        value: "how_much",
        label: "How much do you drink when you drink? ",
        placeholder: "Please describe further.",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
      {
        id: "2",
        value: "how_many_days_a_week",
        label: "How many days a week do you drink?",
        placeholder: "Please describe further.",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
      {
        id: "3",
        value: "how_many_total_drinks_in_a_week",
        label: "How many total drinks in a week?",
        placeholder: "Please describe further.",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.how_much_marijuana_do_you_consume]: {
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        value: "how_much",
        label:
          "How much do you consume in grams/dollars in a week or in a month?",
        placeholder: "Please describe further.",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
      {
        id: "2",
        value: "how_many_days_a_week",
        label: "How many days a week do you use?",
        placeholder: "Please describe further.",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.cut_down_alcohol_use]: {
    type: CONSULTATIONS_QUESTION_TYPES.text,
    inputs: [
      {
        id: "1",
        value: "example",
        label: "EXAMPLE",
        placeholder: "Please describe further.",
        type: CONSULTATIONS_INPUTS_TYPES.text_area,
        required: true,
      },
    ],
  },
  [INTAKE_QUESTION_CODES.family_attempted_suicide_or_died_under_mysterious_circumstances]:
    {
      type: CONSULTATIONS_QUESTION_TYPES.text,
      title:
        "Has anyone in your family attempted or completed suicide, or died under mysterious circumstances?",
      inputs: [
        {
          id: "1",
          value: "value",
          placeholder: "Please enter further description.",
          type: CONSULTATIONS_INPUTS_TYPES.text_area,
          required: true,
        },
      ],
    },
} as const;

export type IntakeTextQuestionValues =
  (typeof textQuestions)[keyof typeof textQuestions]["inputs"][number]["value"];
