import { Avatar } from "~/common/assets";
import {
  ClockIcon,
  ImagePromptCard,
  WaveBackgroundLayout,
} from "~/common/components";

interface StartConsultationSectionProps {
  section: number;
  duration?: string;
  title: string;
  handleContinue: () => void;
  handleGoBack: () => void;
  pendingContinue: boolean;
  providerImageSrc?: string;
}

export const StartConsultationSection = ({
  section,
  duration,
  title,
  handleContinue,
  handleGoBack,
  pendingContinue,
  providerImageSrc,
}: StartConsultationSectionProps) => (
  <WaveBackgroundLayout className="relative justify-center p-6">
    <div className="absolute top-4 flex w-[90%] flex-row items-center justify-between border-b border-primary-300 border-opacity-70 py-2 text-gray-5">
      <p>Section {section}</p>
      <div className="flex flex-row items-center gap-2">
        <ClockIcon />
        <p>{duration}</p>
      </div>
    </div>
    <ImagePromptCard
      title={title}
      handleContinue={handleContinue}
      handleGoBack={handleGoBack}
      pendingContinue={pendingContinue}
      continueLabel="Get started"
      goBackLabel="Continue later"
      imageContainerClassName="bg-primary-30"
    >
      <img
        className="w-32 rounded-full"
        src={providerImageSrc ?? Avatar}
        alt="AaronPicture"
      />
    </ImagePromptCard>
  </WaveBackgroundLayout>
);
