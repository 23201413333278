import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { EmptyState, LoadingState } from "~/common/components";
import { PATIENT_MEDICATION_STATUS } from "~/common/constants";
import { MedicationStatusModal, PatientMedication } from "~/common/types";
import { formatMedicationName } from "~/common/utils";
import { RequestScriptModal } from "~/patients/components/medicationScripts/RequestScriptModal";
import { RequestScriptStatusModal } from "~/patients/components/medicationScripts/RequestScriptStatusModal";
import { useGetPatientMedications } from "~/patients/hooks/queries/medications/useGetPatientMedications";
import { ROUTES } from "~/patients/router";
import { generateMedicationDatesRange } from "~/providers/utils";
import { PatientMedicationCard } from "./PatientMedicationCard";

export const CurrentMedications = () => {
  const [medicationStatusModal, setMedicationStatusModal] =
    useState<MedicationStatusModal>();
  const { data: currentMedications, isLoading } = useGetPatientMedications(
    PATIENT_MEDICATION_STATUS.active,
  );
  const [selectedPatientMedication, setSelectedPatientMedication] =
    useState<PatientMedication>();

  const navigate = useNavigate();

  if (isLoading) return <LoadingState />;

  if (!currentMedications?.data.length)
    return (
      <EmptyState
        message="You have no medications assigned yet."
        className="min-h-80"
      />
    );

  return (
    <>
      <div className="flex flex-col gap-4">
        {currentMedications?.data.map((med) => (
          <PatientMedicationCard
            key={med.id}
            title={formatMedicationName(med.medication)}
            latestScriptRequestDate={med.latest_script_request_date}
            discontinuedAt={med.discontinued_at}
            handleRequestScript={() => setSelectedPatientMedication(med)}
            subtitle={generateMedicationDatesRange(
              med.start_date,
              med.discontinued_at,
            )}
            handleClick={() =>
              navigate(
                generatePath(ROUTES.medicationDetails, {
                  id: med.id,
                }),
              )
            }
          />
        ))}
        <RequestScriptModal
          key={selectedPatientMedication?.id}
          onClose={() => setSelectedPatientMedication(undefined)}
          medicationName={
            selectedPatientMedication &&
            formatMedicationName(selectedPatientMedication?.medication)
          }
          medicationId={selectedPatientMedication?.id ?? ""}
          setMedicationStatusModal={setMedicationStatusModal}
        />
      </div>

      <RequestScriptStatusModal
        medicationStatusModal={medicationStatusModal}
        setMedicationStatusModal={setMedicationStatusModal}
      />
    </>
  );
};
