import { useEffect, useRef, useState } from "react";
import { UseMutateAsyncFunction, useQueryClient } from "@tanstack/react-query";
import {
  UseFormClearErrors,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import { toast } from "react-toastify";

import { useRecordingStore } from "~/common/stores/useRecordingStore";
import { YES_NO_NA_TYPES } from "~/common/utils/consultations/intake";
import { YesNoNAWithAudioOnYesAnswer } from "~/common/utils/consultations/intake/interfaces";
import { AudioRecorderHandle } from "~/patients/components";

export const useHandleExtraDataAudio = (
  handleGoBack: () => void,
  setValue: UseFormSetValue<YesNoNAWithAudioOnYesAnswer>,
  reset: UseFormReset<YesNoNAWithAudioOnYesAnswer>,
  deleteAudioMutation: UseMutateAsyncFunction<null, Error, void, unknown>,
  clearErrors: UseFormClearErrors<YesNoNAWithAudioOnYesAnswer>,
  completedAudioPresignedUrl?: string,
) => {
  const queryClient = useQueryClient();
  const [audioFile, setAudioFile] = useState<File>();
  const audioRecorderRef = useRef<AudioRecorderHandle>(null);

  const { userRecording } = useRecordingStore();

  const handleSuddenStopRecording = () => {
    if (!audioRecorderRef.current?.isRecording) return;
    audioRecorderRef.current.stopRecording();
  };

  const deleteRecordingAndGoBack = () => {
    if (audioRecorderRef.current?.isRecording) {
      audioRecorderRef.current.stopRecording();
      audioRecorderRef.current.handleDeleteRecording();
    }
    handleGoBack();
  };

  const setRecordedAudio = (audioBlob: Blob, duration: number) => {
    const file = new File([audioBlob], "audio.wav", {
      type: audioBlob.type,
    });
    setAudioFile(file);
    setValue(`answer.audio.duration`, duration);
  };

  const handleDeleteAudio = async () => {
    try {
      !!completedAudioPresignedUrl &&
        (await deleteAudioMutation().then(() =>
          queryClient.invalidateQueries({
            queryKey: ["getCurrentIntakeQuestion"],
          }),
        ));
      setAudioFile(undefined);
      reset({ answer: { value: YES_NO_NA_TYPES.yes, audio: undefined } });
    } catch (error) {
      toast.error(
        "There was a problem deleting the audio. If the issue persists, please reload the pageAAAAA.",
      );
    }
  };

  useEffect(() => {
    clearErrors("answer.audio");
  }, [userRecording, clearErrors]);

  return {
    audioFile,
    audioRecorderRef,
    deleteRecordingAndGoBack,
    setRecordedAudio,
    handleDeleteAudio,
    handleSuddenStopRecording,
    userRecording,
  };
};
