import { useState } from "react";

import {
  CardInputsContainer,
  ConfirmActionModal,
  TrashIcon,
  UploadIcon,
  UploadImageModal,
} from "~/common/components";
import {
  useDeleteDriverLicense,
  useUploadDriverLicense,
} from "~/patients/hooks";
import PlusIcon from "../icons/PlusIcon";

interface DriversLicenseProps {
  driversLicense?: string;
  setDriversLicense: (preview?: string) => void;
}

const DriversLicense = ({
  driversLicense,
  setDriversLicense,
}: DriversLicenseProps) => {
  const [uploadImageModalOpen, setUploadImageModalOpen] =
    useState<boolean>(false);
  const [preview, setPreview] = useState(driversLicense);

  const toggleUploadImageModal = () => {
    if (uploadImageModalOpen) {
      setPreview(driversLicense);
    }
    setUploadImageModalOpen((prev) => !prev);
  };

  const [deleteImageModalOpen, setDeleteImageModalOpen] =
    useState<boolean>(false);

  const toggleDeleteImageModal = () => {
    setDeleteImageModalOpen((prev) => !prev);
  };

  const onDeleteSuccess = () => {
    setDriversLicense(undefined);
    setPreview(undefined);
    toggleDeleteImageModal();
  };

  const { deleteImageMutation, isPending } =
    useDeleteDriverLicense(onDeleteSuccess);

  const onDeleteImage = () => {
    deleteImageMutation();
  };

  const handleSuccess = (url: string) => {
    setDriversLicense(url);
    setUploadImageModalOpen(false);
  };

  const { uploadImageMutation, isPending: pendingUpload } =
    useUploadDriverLicense(handleSuccess);

  const handleUpload = (image: File) => {
    uploadImageMutation({ image });
  };

  return (
    <CardInputsContainer title="Drivers license">
      {driversLicense ? (
        <div className="flex w-full flex-col items-center">
          <div className="flex flex-col gap-3">
            <img src={driversLicense} alt="" />
            <div className="flex flex-row justify-between gap-4">
              <button
                type="button"
                className="flex flex-row items-center gap-2 font-medium text-red-500"
                onClick={toggleDeleteImageModal}
              >
                <TrashIcon className="h-4 w-4" />
                Delete image
              </button>
              <button
                type="button"
                className="flex flex-row items-center gap-2 font-medium"
                onClick={toggleUploadImageModal}
              >
                <UploadIcon className="size-4" />
                Upload new image
              </button>
            </div>
          </div>
        </div>
      ) : (
        <button
          onClick={toggleUploadImageModal}
          type="button"
          className="flex h-full w-full flex-col items-center justify-center gap-4"
        >
          <div className="rounded-full bg-gray-100 p-2">
            <PlusIcon className="text-gray-600" />
          </div>
          <div className="flex flex-col items-center gap-2">
            <h2>Upload image</h2>
            <p className="text-sm text-gray-500">
              Drivers license image or other state ID
            </p>
          </div>
        </button>
      )}
      <UploadImageModal
        preview={preview}
        setPreview={setPreview}
        show={uploadImageModalOpen}
        src={driversLicense}
        onClose={toggleUploadImageModal}
        handleUpload={handleUpload}
        loadingSubmit={pendingUpload}
        key={driversLicense}
      />
      <ConfirmActionModal
        isLoading={isPending}
        title="Delete image"
        description="Are you sure you want to delete this image?"
        onConfirm={onDeleteImage}
        show={deleteImageModalOpen}
        onClose={toggleDeleteImageModal}
      />
    </CardInputsContainer>
  );
};

export default DriversLicense;
