import React, { ComponentPropsWithoutRef, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { handleOpenDatePicker } from "~/common/utils/handleOpenDatePicker";
import { ErrorText } from "./typography";

interface DatePickerProps extends ComponentPropsWithoutRef<"input"> {
  label?: string;
  placeholder?: string;
  error?: string;
  className?: string;
  children?: ReactNode;
  defaultValue?: string;
  inputClassName?: string;
  isError?: boolean;
}

const DatePicker = React.forwardRef<
  HTMLInputElement,
  Omit<DatePickerProps, "type">
>(
  (
    {
      id,
      label,
      error,
      placeholder,
      className,
      defaultValue,
      children,
      inputClassName,
      isError = false,
      ...props
    }: DatePickerProps,
    ref,
  ) => {
    return (
      <div className="flex w-full flex-col gap-2 font-light text-gray-700">
        <div className="flex w-fit flex-col">
          {label && (
            <label className="inline w-full text-sm font-medium" htmlFor={id}>
              {label}
            </label>
          )}
        </div>
        <div
          className={twMerge(
            "flex w-full items-center justify-between rounded-md border border-gray-20 bg-neutral-25 text-sm",
            className,
            (isError || error) && "border-error bg-red-50",
          )}
        >
          <input
            className={twMerge(
              "h-11 w-full rounded-md border-0 bg-transparent text-sm placeholder:text-left focus:outline-none focus:ring-0 disabled:bg-gray-200 disabled:text-gray-60",
              (isError || error) && "text-error",
              inputClassName,
            )}
            id={id}
            type="date"
            onClick={handleOpenDatePicker}
            placeholder={placeholder ?? `Enter ${label?.toLocaleLowerCase()}`}
            {...props}
            ref={ref}
            defaultValue={defaultValue}
          />
          {children}
        </div>
        {error?.trim() && <ErrorText>{error}</ErrorText>}
      </div>
    );
  },
);

DatePicker.displayName = "DatePicker";
export default DatePicker;
