import { twMerge } from "tailwind-merge";

import { GreenTickIcon } from "./icons";
import { CancelSubmitButtons } from "./ui";

interface FormStateBottomBarProps {
  successMessage: string;
  isDirty: boolean;
  isSubmitSuccessful: boolean;
  handleCancel: () => void;
  isPending?: boolean;
}

export const FormStateBottomBar = ({
  successMessage,
  isDirty,
  isSubmitSuccessful,
  handleCancel,
  isPending,
}: FormStateBottomBarProps) => (
  <div
    className={twMerge(
      "sticky bottom-0 z-50 flex h-[72] w-full items-center justify-end gap-2 bg-white p-4 font-semibold text-gray-700 shadow-strong transition duration-500",
      isDirty && "bg-blue-100",
      isDirty &&
        isSubmitSuccessful &&
        "justify-between bg-green-100 md:justify-end",
    )}
  >
    {isDirty && !isSubmitSuccessful && (
      <p className="hidden px-4 transition duration-500 md:flex">
        Unsaved changes
      </p>
    )}
    {isSubmitSuccessful && (
      <div className="flex items-center gap-2">
        <GreenTickIcon className="size-7 md:size-5" />
        <p className="hidden md:flex">{successMessage}</p>
      </div>
    )}
    <CancelSubmitButtons
      containerClassName="w-fit"
      submitButtonLabel="Save changes"
      cancelClassName="w-fit"
      submitClassName="w-fit"
      disableSubmit={!isDirty || isSubmitSuccessful}
      handleCancel={handleCancel}
      isSubmitting={isPending}
    />
  </div>
);
