import { useNavigate } from "react-router-dom";

import { Button, CopyIcon, Typography } from "~/common/components";
import SuccessErrorLayout from "~/common/components/layouts/SuccessErrorLayout";
import { copyToClipboard } from "~/common/utils";
import { ROUTES } from "~/patients/router";

interface PaymentFinalMessageProps {
  paymentIntent: string | null;
  isError?: boolean;
}
export const PaymentFinalMessage = ({
  paymentIntent,
  isError = false,
}: PaymentFinalMessageProps) => {
  const navigate = useNavigate();

  const title = isError ? "Something went wrong" : "Payment received";
  const message = isError
    ? "There was an error processing your payment. Please contact our support team."
    : "Your payment has been received successfully.";

  const handleNavigate = () =>
    isError
      ? navigate(ROUTES.leadPatientHome)
      : navigate(ROUTES.completeIntakeConsultation);

  const buttonLabel = !isError ? "Continue" : "Back to home";

  return (
    <SuccessErrorLayout
      success={!isError}
      title={title}
      description={message}
      className="h-screen-calculated md:w-full"
    >
      <div className="w-full space-y-6 px-6">
        {paymentIntent && (
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-1">
              <Typography className="text-center">
                Payment confirmation number:
              </Typography>
              <button
                onClick={() => copyToClipboard(paymentIntent ?? "")}
                className="flex flex-row items-center justify-center gap-2"
              >
                <Typography className="text-center font-bold">
                  {paymentIntent}
                </Typography>
                <CopyIcon className="size-5" />
              </button>
            </div>
            <Typography className="text-center text-sm text-gray-700">
              You can save this payment reference in case you need to contact
              the PsychNow Team regarding your payment.
            </Typography>
          </div>
        )}
        <Button
          onClick={handleNavigate}
          variant={isError ? "outline-none-underline" : "primary"}
          className="w-full"
        >
          {buttonLabel}
        </Button>
      </div>
    </SuccessErrorLayout>
  );
};
