import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { CancelSubmitButtons, TransitionEffect } from "~/common/components";

interface ConsultationQuestionsLayoutProps extends PropsWithChildren {
  title?: string;
  handleNext: () => void;
  handleBack: () => void;
  disableNext?: boolean;
  disableBack: boolean;
  isSubmitting: boolean;
  supertitle?: string;
  subtitle?: string;
  errors?: string;
  submitButtonLabel?: string;
  cancelSubmitButtonsClassName?: string;
}

export const ConsultationQuestionsLayout = ({
  children,
  title,
  handleNext,
  handleBack,
  disableNext = false,
  disableBack,
  isSubmitting,
  subtitle,
  supertitle,
  errors,
  submitButtonLabel,
  cancelSubmitButtonsClassName,
}: ConsultationQuestionsLayoutProps) => (
  <TransitionEffect
    className="items-left flex w-full flex-grow flex-col gap-11 overflow-y-auto md:h-2/3 md:w-1/2 xl:w-1/4"
    duration={300}
  >
    {supertitle && <p>{supertitle}</p>}
    {(title ?? subtitle) && (
      <div className="flex flex-col gap-2">
        <p className="text-lg font-medium leading-5 first-letter:capitalize">
          {title}
        </p>
        <p className="italic">{subtitle}</p>
      </div>
    )}
    <form
      className="flex size-full flex-grow flex-col justify-between gap-3 text-gray-70"
      onSubmit={handleNext}
    >
      <div className="flex h-full flex-col justify-between gap-3">
        <div className="flex flex-col gap-2">
          <div className="flex w-full flex-col gap-3">{children}</div>
          {errors && <p className="text-sm text-red-600">{errors}</p>}
        </div>
        <CancelSubmitButtons
          variant="separated-outline-none"
          cancelButtonLabel="Back"
          isSubmitting={isSubmitting}
          handleCancel={handleBack}
          disableSubmit={disableNext}
          submitButtonLabel={submitButtonLabel ?? "Next"}
          containerClassName={twMerge(
            disableBack && "justify-end",
            cancelSubmitButtonsClassName,
          )}
          cancelClassName={twMerge(disableBack && "hidden")}
        />
      </div>
    </form>
  </TransitionEffect>
);
