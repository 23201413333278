import { useState } from "react";
import { Navigate } from "react-router-dom";

import { ChevronIcon, LoadingState, SectionHeader } from "~/common/components";
import { DIRECTIONS, PATIENT_STATUS } from "~/common/constants";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { ROUTES } from "~/patients/router";
import { CurrentScripts } from "./CurrentScripts";
import { PastScripts } from "./PastScripts";

export const Scripts = () => {
  const { data: user, isLoading: isUserLoading } = useGetCurrentPatient();

  const [showScriptDetails, setShowScriptDetails] = useState(false);

  const handleShowDetails = () => setShowScriptDetails((prev) => !prev);

  if (isUserLoading) return <LoadingState />;

  if (user?.status !== PATIENT_STATUS.ongoing_care)
    return <Navigate to={ROUTES.notFound} />;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex h-full flex-col gap-4">
        <SectionHeader>Current requests</SectionHeader>
        <CurrentScripts />
      </div>
      <div className="flex h-full flex-col gap-4">
        <div className="flex items-center justify-between">
          <SectionHeader>Past requests</SectionHeader>
          <button onClick={handleShowDetails}>
            <ChevronIcon
              direction={showScriptDetails ? DIRECTIONS.top : DIRECTIONS.down}
            />
          </button>
        </div>
        {showScriptDetails && <PastScripts />}
      </div>
    </div>
  );
};
